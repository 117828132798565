import { useState } from 'react';
import './Player.css';

function Player({playerName, costume, isMuted, talking, level, hpPercentage}) {
  const [previousHealthLevel, setPreviousHealthLevel] = useState(getHealthLevelPng(hpPercentage));
  const healthLevel = getHealthLevelPng(hpPercentage);

  let imgToShow = talking ? 'Talking.png' : 'Mute.png';
  if (level.skill !== 'Hide') {
    imgToShow = 'LevelUp.webp'
  }

  let animationClass = imgToShow === 'Talking.png' || imgToShow === 'LevelUp.webp' ? 'player-img' : 'player-img-mute';
  if (previousHealthLevel !== healthLevel) {
    animationClass = 'player-img-dmg';    
  }

  if (hpPercentage <= 0) {
    animationClass += ' player-killed'
  }

  return (
    <div>
        <img id='player-png' style={{ filter: isMuted ? 'brightness(40%)' : null}} onAnimationEnd={() => { if (animationClass === 'player-img-dmg') setPreviousHealthLevel(healthLevel) }} className={ animationClass } src={`${process.env.PUBLIC_URL}/Players/${playerName}/${costume}/${healthLevel}_${imgToShow}`}  />
    </div>
  );
}

function getHealthLevelPng(percentage) {
  if (percentage <= 25) return 25;
  if (percentage <= 50) return 50;
  if (percentage <= 75) return 75;
  return 100;
}

export default Player;
