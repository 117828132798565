export const playerIdToName = {
    0: "horobol",
    1: "mrlabrador",
    2: "spaceyeen",
    3: "preyxpurge",
    4: "honk"
}

export const playerNameToHeadOffset = {
    "horobol": {
        top: 80,
        left: 260
    },
    "mrlabrador": {
        top: 90,
        left: 260
    },
    "spaceyeen": {
        top: 80,
        left: 260
    },
    "preyxpurge": {
        top: 130,
        left: 135
    },
    "honk": {
        top: 100,
        left: 260
    }
}

export const getCurrentPng = (playerName, costume, talking, hpPercentage) => {
    const healthLevel = getHealthLevelPng(hpPercentage);

    let imgToShow = talking ? 'Talking.png' : 'Mute.png';
    return `${process.env.PUBLIC_URL}/Players/${playerName}/${costume}/${healthLevel}_${imgToShow}`;
}

function getHealthLevelPng(percentage) {
    if (percentage <= 25) return 25;
    if (percentage <= 50) return 50;
    if (percentage <= 75) return 75;
    return 100;
  }