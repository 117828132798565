import './MainOverlay.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { playerIdToName } from './utils';
import Player from './Player';
import LevelUp from './LevelUp';
import OverlayEffect from './OverlayEffect';

function MainOverlay() {
  const { playerId = 0 } = useParams();

  const [playerMuted, setPlayerMuted] = useState(false);
  const [playerTalking, setPlayerTalking] = useState(false);
  const [playerStats, setPlayerStats] = useState({
    health: 10,
    maxHealth: 10,
    stamina: 10,
    maxStamina: 10,
    magic: 1,
    maxMagic: 10,
    souls: 10,
    is_poisoned: false ,
    is_rot: false
  });
  const [playerLevel, setPlayerLevel] = useState({ skill: 'Hide', level: 60 });
  const [playerCostume, setPlayerCostume] = useState(0);

  const playerName = playerIdToName[playerId];

  useEffect(() => {

    const quicWorker = new Worker(new URL('QuicWorker.js', import.meta.url));
    quicWorker.onmessage = ({ data }) => {

      console.log(data)
      const message = JSON.parse(data);

      switch (message.event_type) {
        case 'game':
          return setPlayerStats({
            health: message.data.health,
            maxHealth: message.data.max_health,
            stamina: message.data.stamina,
            maxStamina: message.data.max_stamina,
            magic: message.data.magic,
            maxMagic: message.data.max_magic,
            souls: message.data.souls,
            is_poisoned: message.data.is_poisoned,
            is_rot: message.data.is_rot
          });
        case 'mic':
          return setPlayerTalking(message.data.active);
        case 'key':
          return setPlayerCostume(message.data.key_code);
      }
    }
    quicWorker.postMessage({
      url: "https://micro.horo.boo:8080",
      player_id: playerId
    });

    return () => { quicWorker.terminate(); }
  }, []);

  const hpPercentage = (playerStats.health / playerStats.maxHealth) * 100;
  const magicPercentage = (playerStats.magic / playerStats.maxMagic) * 100;
  const staminaPercentage = (playerStats.stamina / playerStats.maxStamina) * 100;

  return (
    <div className="main">
      <LevelUp level={playerLevel} />
      <div className='player'>
        <Player playerName={playerName.toLowerCase()} costume={playerCostume} isMuted={playerMuted} talking={playerTalking} level={playerLevel} hpPercentage={hpPercentage} />
        <OverlayEffect
          playerName={playerName.toLowerCase()}
          costume={playerCostume}
          talking={playerTalking}
          hpPercentage={hpPercentage}
          magicPercentage={magicPercentage}
          staminaPercentage={staminaPercentage}
          is_poisoned={playerStats.is_poisoned}
          is_rot={playerStats.is_rot}
        />
      </div>
    </div>
  );
}

export default MainOverlay;
