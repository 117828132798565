import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';

import MainOverlay from './MainOverlay';
import StatsOverlay from './StatsOverlay';

const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <MainOverlay />,
  // },
  // {
  //   path: '/stats',
  //   element: <StatsOverlay />,
  // },
  {
    path: '/players/:playerId',
    element: <MainOverlay />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
