import './LevelUp.css';

const levelUpSprites = {
    Attack: 'Icons/Attack_icon.png',
    Defence: 'Icons/Defence_icon.png',
    Magic: 'Icons/Magic_icon.png',
    Ranged: 'Icons/Ranged_icon.png',
    Prayer: 'Icons/Prayer_icon.png',
    Strength: 'Icons/Strength_icon.png',
    Cooking: 'Icons/Cooking_icon.png',
    Woodcutting: 'Icons/Woodcutting_icon.png',
    Fletching: 'Icons/Fletching_icon.png',
    Fishing: 'Icons/Fishing_icon.png',
    Firemaking: 'Icons/Firemaking_icon.png',
    Crafting: 'Icons/Crafting_icon.png',
    Smithing: 'Icons/Smithing_icon.png',
    Mining: 'Icons/Mining_icon.png',
    Herblore: 'Icons/Herblore_icon.png',
    Agility: 'Icons/Agility_icon.png',
    Thieving: 'Icons/Thieving_icon.png',
    Slayer: 'Icons/Slayer_icon.png',
    Farming: 'Icons/Farming_icon.png',
    Runecraft: 'Icons/Runecraft_icon.png',
    Hunter: 'Icons/Hunter_icon.png',
    Construction: 'Icons/Construction_icon.png',
    Hitpoints: 'Icons/Hitpoints_icon.png',
    Hide: 'Icons/Magic_icon.png', // Need hidden icon to take up space to avoid png displacement
}

function LevelUp({ level }) {
    return (
        <div className='level wave glow' style={{visibility: level.skill !== 'Hide' ? 'visible' : 'hidden'}}>
            <img className='char1' style={{verticalAlign: 'middle'}} src={`${process.env.PUBLIC_URL}/${levelUpSprites[level?.skill]}`}  />
            {` ${level?.skill} level has increased to ${level?.level}`.split('').map((char, idx) => (
                <span className={`char${idx + 2}`} dangerouslySetInnerHTML={{__html: char !== ' ' ? char : '&nbsp;'}} />
            ))}
        </div>
    );
}

export default LevelUp;