import { useState } from 'react';
import './OverlayEffect.css';
import { getCurrentPng, playerNameToHeadOffset } from './utils';

function generateDripPosition() {
    return {
        top: (Math.random() * (320-120) + 80),
        left: (Math.random() * (380-80) + 140)
    }
}

function Effect({animation, image, delay = 0, rotate = 0}) {
    const [effectPosition, setEffectPosition] = useState(generateDripPosition());

    return (
        <>
            <img
                key={effectPosition.top + effectPosition.left}
                style={{rotate: effectPosition.left > 140 ? `${Math.random() * rotate}deg` : `${Math.random() * -rotate}deg`, ...effectPosition}} 
                className={`effect ${animation}`} 
                src={image}
                onAnimationEnd={() => setTimeout(() => setEffectPosition(generateDripPosition()), delay)}
            />
        </>
    )
}

function OverlayEffect({ playerName, costume, talking, hpPercentage, magicPercentage, staminaPercentage, is_poisoned, is_rot }) {
    return (
        <>
        { // Death Effects
            hpPercentage <= 0 &&
            <>
                <img className='effect bloodsplatter-anim' src={`${process.env.PUBLIC_URL}/Icons/DD/blood_splatter_icon_1.png`} />
                <img style={playerNameToHeadOffset[playerName]} className='effect deathblow-anim' src={`${process.env.PUBLIC_URL}/Icons/DD/deathblow_icon.png`}/>
                <span
                    style={{
                        color: '#970201',
                        rotate: '7deg',
                        fontSize: 'xx-large',
                        WebkitTextStroke: '#3d0000',
                        WebkitTextStrokeWidth: 'thin',
                        zIndex: 50,
                        ...playerNameToHeadOffset[playerName]
                    }}
                    className='effect status-anim'
                >Death Blow!</span>
            </>
        }
        {// Not poison
            !is_poisoned &&
            <>
                <div style={{ opacity: 0, maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-green halo-anim-reverse' />
            </>
        }
        { // Poison effects
            is_poisoned &&
            <>
                <Effect animation={'drip-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/poison_icon.png`} delay={250} />
                <Effect animation={'drip-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/poison_icon.png`} delay={500} />
                <Effect animation={'drip-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/poison_icon.png`} delay={750} />
                <div style={{ opacity: 0.7, maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-green' />
                <div style={{ opacity: 0.7, animationDelay: '0.2s', maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-green' />
                <span
                    style={{
                        color: '#008f17',
                        fontSize: 'xx-large',
                        WebkitTextStroke: '#00400a',
                        WebkitTextStrokeWidth: 'thin',
                        zIndex: 50,
                        ...playerNameToHeadOffset[playerName]
                    }}
                    className='effect status-anim'
                >Poisoned!</span>
            </>
        }
        {// Not rot
            !is_rot &&
            <>
                <div style={{ opacity: 0, maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-red halo-anim-reverse' />
            </>
        }
        { // Rot effects
            is_rot &&
            <>
                <Effect animation={'pop-in-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/boils_icon.png`} delay={1000} rotate={30} />
                <Effect animation={'pop-in-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/boils_icon.png`} delay={1500} rotate={30} />
                <Effect animation={'pop-in-anim'} image={`${process.env.PUBLIC_URL}/Icons/DD/boils_icon.png`} delay={2000} rotate={30} />
                <div style={{ opacity: 0.4, maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`,  WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-red' />
                <div style={{ opacity: 0.4, animationDelay: '0.4s', maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-red' />
                <div style={{ opacity: 0.4, animationDelay: '0.8s', maskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})`, WebkitMaskImage: `url(${getCurrentPng(playerName, costume, talking, hpPercentage)})` }} className='effect effect-gradient-red' />
                <span
                    style={{
                        color: '#970201',
                        fontSize: 'xx-large',
                        WebkitTextStroke: '#3d0000',
                        WebkitTextStrokeWidth: 'thin',
                        zIndex: 50,
                        ...playerNameToHeadOffset[playerName]
                    }}
                    className='effect status-anim'
                >Rot!</span>
            </>
        }
        { // Not low magic (lol)
            magicPercentage > 20 &&
            <>
                <img style={{opacity:0, ...playerNameToHeadOffset[playerName]}} className='effect halo-anim-reverse' src={`${process.env.PUBLIC_URL}/Icons/DD/low_magic_halo_icon.png`} />
            </>
        }
        { // Low Magic
            magicPercentage <= 20 &&
            <>
                <img style={playerNameToHeadOffset[playerName]} className='effect halo-anim' src={`${process.env.PUBLIC_URL}/Icons/DD/low_magic_halo_icon.png`} />
                <img 
                    style={{
                        // rotate: '-30deg',
                        width: '25px',
                        top: playerNameToHeadOffset[playerName].top - 55,
                        left: playerNameToHeadOffset[playerName].left + 38
                    }}
                    className='effect pulse-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/low_magic_fizzle_big_icon.png`}
                />
                <img 
                    style={{
                        top: playerNameToHeadOffset[playerName].top - 50,
                        left: playerNameToHeadOffset[playerName].left + 50
                    }}
                    className='effect pulse-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/low_magic_fizzle_big_icon.png`}
                />
                <img 
                    style={{
                        // rotate: '35deg',
                        width: '20px',
                        top: playerNameToHeadOffset[playerName].top - 55,
                        left: playerNameToHeadOffset[playerName].left + 67
                    }}
                    className='effect pulse-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/low_magic_fizzle_big_icon.png`}
                />
                <span
                    style={{
                        color: '#8ccffe',
                        fontSize: 'xx-large',
                        WebkitTextStroke: '#076aaf',
                        WebkitTextStrokeWidth: 'thin',
                        ...playerNameToHeadOffset[playerName]
                    }}
                    className='effect status-anim'
                >Low Mana!</span>
            </>
        }
        { // Low Stamina
            staminaPercentage <= 40 &&
            <>
                <img 
                    style={{
                        width: '50px',
                        top: playerNameToHeadOffset[playerName].top - 60,
                        left: playerNameToHeadOffset[playerName].left + 95  
                    }}
                    className='effect sweat-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/sweat_icon.png`}
                />
                <img 
                    style={{
                        width: '50px',
                        top: playerNameToHeadOffset[playerName].top - 45,
                        left: playerNameToHeadOffset[playerName].left + 125
                    }}
                    className='effect sweat-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/sweat_icon.png`}
                />
                <img 
                    style={{
                        width: '50px',
                        top: playerNameToHeadOffset[playerName].top - 15,
                        left: playerNameToHeadOffset[playerName].left + 140
                    }}
                    className='effect sweat-anim'
                    src={`${process.env.PUBLIC_URL}/Icons/DD/sweat_icon.png`}
                />
            </>
        }
        </>
    )
}

export default OverlayEffect;